import React from "react";
import { logFirebaseEvent } from "../../utils/analytics";
import { useNavigate } from "react-router-dom";
import YoutubeEmbed from "../../Components/common/YoutubeEmbed";
import LinkedIn from "../../assets/image/svg/linked_in_blue.svg";
import LinkedInBlue from "../../assets/image/svg/linkedin-create-resume.svg";
import Plus from "../../assets/image/plus-blue.png";
import Logo from "../../assets/image/svg/Logo.svg";
import Edmond from "../../assets/image/linkedin-image/Edmond.png";
import Joseph from "../../assets/image/linkedin-image/Joseph.png";
import Sushant from "../../assets/image/linkedin-image/Sushant.png";
import TestimonialsCarousel from "../../Components/common/TestimonialCardCarousel";

const LinkedInFollow = () => {
  const navigate = useNavigate();
  return (
    <div className="app-container">
      <div className="coach-header p-2">
        <a onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" width={170} className="mt-1" />
        </a>
      </div>

      <section className="mx-3 mx-md-5 mt-70 pt-5">
        <h1
          className="text-center text-white fs-1"
          style={{ lineHeight: "2.75rem" }}
        >
          Ready to Land Your Dream Job? Get $3,500 <br /> Worth of Proven
          Strategies — For $0!
        </h1>
        <p className="text-center text-white fs-2 mt-3">
          I&apos;m Amit Patel, and I&apos;m about to share something most <br />
          career coaches would charge thousands of dollars for — <br /> but
          it&apos;s yours completely free.
        </p>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6">
            <YoutubeEmbed embedId="Jr_IsL9h1Lo" mute={0} />
          </div>

          <div className="d-flex justify-content-center align-items-center my-4">
            <a
              href="https://www.linkedin.com/in/aamitmit/"
              className="linkedin-follow-btn2 mx-3 rounded-pill w-150"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logFirebaseEvent("EVERYTHING_QUICK_FREE_FOLLOW_PLUS_BTN");
              }}
            >
              Follow
              <img src={Plus} alt="plus_icon" height={20} className="mt-n3" />
            </a>
            <a
              href="https://www.linkedin.com/in/aamitmit/"
              className="linkedin-follow-btn2 align-items-center rounded-pill w-150"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logFirebaseEvent("EVERYTHING_QUICK_FREE_LINKEDIN_BTN");
              }}
            >
              Linked
              <img
                src={LinkedIn}
                alt="linkedIn"
                height={20}
                className="mt-n4"
              />
            </a>
          </div>

          <p className="text-center text-white mt-3 fs-3">
            {`- Join 6,000+ Professionals Following Amit on LinkedIn -`}
          </p>
          <hr className="custom-hr" />
        </div>
      </section>

      <TestimonialsCarousel
        Edmond={Edmond}
        Joseph={Joseph}
        Sushant={Sushant}
        LinkedInBlue={LinkedInBlue}
      />
    </div>
  );
};

export default LinkedInFollow;
