import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, mute }) => {
  return (
    <div className="video-wrap">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=${mute}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        loading="lazy"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  mute: PropTypes.number.isRequired,
};

YoutubeEmbed.defaultProps = {
  embedId: "",
  mute: 1,
};
export default YoutubeEmbed;
