import firebase from "firebase/compat/app";
import getStripe from "../initializeStripe";
import constants from "../stripeConstants";

const {
  PROD_PRICE_ID,
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
  LOADING_TIMEOUT,
} = constants;

// Branding Boost plan
export async function brandingBoostPlanCS(
  uid,
  setIsloading,
  cancelUrl,
  successUrl
) {
  const firestore = firebase.firestore();
  const updatedSuccessUrl = successUrl
    ? successUrl
    : STRIPE_SUCCESS_URL.PREMIUM_SERVICES;
  const updatedCancelUrl = cancelUrl
    ? cancelUrl
    : STRIPE_CANCEL_URL.PREMIUM_SERVICES;

  setIsloading(true);
  const checkoutSessionRef = await firestore
    .collection("users-stripe")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: PROD_PRICE_ID.BRANDING_BOOST,
      success_url: updatedSuccessUrl,
      cancel_url: updatedCancelUrl,
      allow_promotion_codes: true,
      mode: "payment",
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, LOADING_TIMEOUT);
    }
  });
}

// Branding Boost plan
export async function resumeRebrandPlanCS(
  uid,
  setIsloading,
  cancelUrl,
  successUrl
) {
  const firestore = firebase.firestore();
  const updatedSuccessUrl = successUrl
    ? successUrl
    : STRIPE_SUCCESS_URL.PREMIUM_SERVICES;
  const updatedCancelUrl = cancelUrl
    ? cancelUrl
    : STRIPE_CANCEL_URL.PREMIUM_SERVICES;

  setIsloading(true);
  const checkoutSessionRef = await firestore
    .collection("users-stripe")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: PROD_PRICE_ID.RESUME_REBRAND,
      success_url: updatedSuccessUrl,
      cancel_url: updatedCancelUrl,
      allow_promotion_codes: true,
      mode: "payment",
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, LOADING_TIMEOUT);
    }
  });
}
