import { useState, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { db } from "../../firebase";
import { AuthContext } from "../../Contexts/AuthContext";
import constant from "../../utils/constant";

const { DB_NAME } = constant;

const useFetchWlAccessList = () => {
  const { currentUser } = useContext(AuthContext);
  const uid = get(currentUser, "uid", null);
  const email = get(currentUser, "email", null);

  const [isLoading, setIsLoading] = useState(false);
  const [wlAdminAccessList, setWlAdminAccessList] = useState([]);
  const [wlAdminEmailList, setWlAdminEmailList] = useState([]);
  const [wlValueByEmail, setWlValueByEmail] = useState("");

  const fetchWriterClientsResume = async () => {
    setIsLoading(true);
    await db
      .collection(DB_NAME.WL_ADMIN_ACCESS_List)
      .doc("data")
      .get()
      .then((doc) => {
        setIsLoading(false);
        if (!isEmpty(doc.data())) {
          const data = get(doc.data(), "data", []);
          setWlAdminAccessList(data);

          if (!isEmpty(data)) {
            const emailList = data.map((item) => item.email);
            const [dataObj = {}] = data.filter((item) => item.email === email);
            setWlAdminEmailList(emailList);
            setWlValueByEmail(get(dataObj, "whiteLabel", ""));
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (uid) {
      fetchWriterClientsResume();
    }
  }, []);

  return { isLoading, wlAdminAccessList, wlAdminEmailList, wlValueByEmail };
};

export default useFetchWlAccessList;
