export const whiteLabelList = [
  {
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/whitelabel%2Flogo%2Flogo_saurabh.png?alt=media&token=1a39ee76-e085-42b7-90ac-b97586b47c7a",

    companyName: "testing1 Company",
    createdTime: "2023-09-12T10:18:30+05:30",
    email: "testing1@gmail.com",
    themeColor: "#467f3c",
    uid: "9455259b-d4f6-44f8-a909-a7f71c0eca15",
    whiteLabel: "testing1",
    youtubeEmbedId: "Epjv329Agm4",
  },
  {
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/writer-whitelabel%2F2nvVzZAGCCZMU8hfMYYbO8okHax1%2FScreenshot%20(122).png?alt=media&token=4149bc6b-a4a9-4dc5-9390-12df2cdd6289",
    companyName: "testing2 Company",
    createdTime: "2023-09-10T10:15:30+05:30",
    email: "testing2@gmail.com",
    themeColor: "#d32727",
    uid: "987987fh8sfhfghsfg7s9fj798g7jdghj",
    whiteLabel: "testing2",
    youtubeEmbedId: "LXb3EKWsInQ",
  },
  {
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/whitelabel%2Flogo%2Flogo_femalesandfinance1.png?alt=media&token=8645526d-b40e-4a14-862f-38b92ab520c3",
    companyName: "Females And Finance",
    createdTime: "2023-09-12T10:15:30+05:30",
    email: "",
    themeColor: "#d32727",
    uid: "9455259b-d4f6-44f8-a909-sssssss645546",
    whiteLabel: "femalesandfinance",
  },
];
