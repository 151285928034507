import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getCoursesTabColor,
  getServicesTabColor,
  getCarrerChoosingTabColor,
} from "../../helper/common/commonHelper";
import { logFirebaseEvent } from "../../utils/analytics";
import accessConstants from "../../utils/accessConstants";

const { HEADER_TAB_ACCESS } = accessConstants;

const AiToolsHeaderPopup = ({
  page,
  pageList,
  isShowHeader,
  wlThemeColor,
  openPopup,
  setOpenPopup,
  setOpenDropdown,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const email = currentUser?.email;

  const toggleDropdown = () => {
    setOpenPopup(!openPopup);
    setOpenDropdown(false);
  };

  const handleClickCarrerQuiz = () => {
    logFirebaseEvent("CARRER_CHOOSING_TAB_MAIN_HEADER");

    if (currentUser) {
      navigate("/carrer-choosing");
      setOpenPopup(false);
    } else {
      navigate("/login");
      setOpenPopup(false);
    }
  };

  const handleClickServices = () => {
    logFirebaseEvent("CARRER_CHOOSING_TAB_MAIN_HEADER");
    navigate("/services");
  };

  return (
    <div className="dropdown" onClick={() => setOpenPopup(!openPopup)}>
      <div
        href="#"
        role="button"
        id="dropdownMenuLink"
        onClick={toggleDropdown}
        aria-expanded={openPopup}
        className={`d-flex justify-content-between align-content-center ${
          openPopup ? "text-primary" : ""
        }`}
      >
        Premium Services
        <span className="dropdown-arrow">
          {openPopup ? (
            <span className="material-symbols-outlined rotate-up">
              keyboard_arrow_up
            </span>
          ) : (
            <span className="material-symbols-outlined rotate-down">
              keyboard_arrow_down
            </span>
          )}
        </span>
      </div>

      {openPopup && (
        <ul
          className="dropdown-menu dropdown-menu-start dropdown-menu-lg-end horizontal-dropdown"
          aria-labelledby="dropdownMenuLink"
          style={{
            position: "fixed",
            left: 0,
            justifyContent: "center",
          }}
        >
          {(!currentUser ||
            HEADER_TAB_ACCESS.includes(email) ||
            isShowHeader?.isShowResumeTab) && (
            <li>
              <a
                className="dropdown-item"
                role="button"
                onClick={handleClickServices}
                style={{
                  color: getServicesTabColor(page, wlThemeColor, pageList),
                }}
              >
                1-on-1 Coaching and Resume Services
              </a>
            </li>
          )}

          {(!currentUser ||
            HEADER_TAB_ACCESS.includes(email) ||
            isShowHeader?.isShowCoverLetterTab) && (
            <li>
              <a
                className="dropdown-item"
                role="button"
                onClick={() => {
                  logFirebaseEvent("COURSES_TAB_MAIN_HEADER");
                  navigate("/courses");
                }}
                style={{
                  color: getCoursesTabColor(page, wlThemeColor),
                }}
              >
                Courses
              </a>
            </li>
          )}

          <li>
            <a
              className="dropdown-item"
              role="button"
              onClick={handleClickCarrerQuiz}
              style={{
                color: getCarrerChoosingTabColor(page, wlThemeColor, pageList),
              }}
            >
              Career Quiz
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

AiToolsHeaderPopup.defaultProps = {
  page: "",
  pageList: "",
  isShowHeader: null,
  wlThemeColor: "",
};

export default AiToolsHeaderPopup;
