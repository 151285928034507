import React from "react";
import PropTypes from "prop-types";
import Logo from "../../assets/image/svg/Logo.svg";

const Loader = ({ text, showBottomText }) => {
  return (
    <>
      <div className="loader-fill">
        <div className="loader-body">
          <span className="loader-logo mb-3 mb-md-4">
            <img loading="lazy" src={Logo} alt="" />
          </span>
          <div className="loader-bar">
            <div className="loader-bar-line"></div>
          </div>

          {showBottomText && (
            <>
              <div
                className="loader-text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
              <div className="text-dark fs-2 text-bold">
                I promise! You will love the results!
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};


Loader.propTypes = {
  text: PropTypes.string,
  showBottomText: PropTypes.bool,
};

Loader.defaultProps = {
  text: "Hang tight, AI machines are working for you.",
  showBottomText: true,
};

export default Loader;
