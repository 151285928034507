import React from "react";
import { Helmet } from "react-helmet-async";
import useFetchWlNameLogoComName from "../../hooks/whiteLabel/useFetchWlNameLogoComName";
import constants from "../../utils/constant";
import { isCustomResumeDomain } from "../../utils/functions";

const { HEAD_CONTENT } = constants;

const CustomHead = ({ title, description }) => {
  const { wlCompanyName } = useFetchWlNameLogoComName();
  let updatedTitle = title;
  let updatedDescription = description;

  if (wlCompanyName) {
    updatedTitle = title
      .replaceAll("CareerClaw", wlCompanyName)
      .replaceAll("Career Claw", wlCompanyName);
    updatedDescription = description.replaceAll("CareerClaw", wlCompanyName);
  }

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{updatedTitle}</title>
      <meta name="description" content={updatedDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={updatedTitle} />
      <meta property="og:description" content={updatedDescription} />
      <meta
        property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/Logo%2FCarrerClaw%2Fcarrer-claw.svg?alt=media&token=1f2862a5-308c-4c5b-9ac9-cec3835b108e"
      ></meta>

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={updatedTitle} />
      <meta property="twitter:description" content={updatedDescription} />
      <meta
        property="twitter:image"
        content="https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/Logo%2FCarrerClaw%2Fcarrer-claw.svg?alt=media&token=1f2862a5-308c-4c5b-9ac9-cec3835b108e"
      ></meta>
    </Helmet>
  );
};

CustomHead.defaultProps = {
  title: isCustomResumeDomain()
    ? "CustomResume.us - Career Clarity, Career Mastery."
    : HEAD_CONTENT.title,
  description: HEAD_CONTENT.description,
};

export default CustomHead;
