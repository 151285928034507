import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import get from "lodash/get";

import Loader from "./Loader";
import useFetchWlAccessList from "../../hooks/whiteLabel/useFetchWlAccessList";
import { useAuth } from "../../Contexts/AuthContext";
import { logFirebaseEvent } from "../../utils/analytics";
import constant from "../../utils/constant";
import accessConstants from "../../utils/accessConstants";

const { WRITER_SCREEN } = constant;
const { ALL_CLIENTS_TAB_ACCESS, WHITE_LABEL_TAB_ACCESS, ALL_USER_TAB_ACCESS } =
  accessConstants;

const Sidebar = ({ children }) => {
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", null);
  const email = get(currentUser, "email", null);
  const { isLoading: isFetchWlList, wlAdminEmailList } = useFetchWlAccessList();
  const [page, setPage] = useState("");

  const isShowAllCLientsTab = () => {
    return (
      ALL_CLIENTS_TAB_ACCESS.includes(email) || wlAdminEmailList.includes(email)
    );
  };

  const handleCopyShareUrl = async () => {
    logFirebaseEvent(`SHARE_URL_TAB_${WRITER_SCREEN.SIDEBAR}`);
    const url = `${window.location.origin}/#/submit-information/?writer=${uid}`;

    try {
      await navigator.clipboard.writeText(url);
      toast.success("Add client through copied URL");
    } catch (err) {
      toast.error("Copy Failed");
    }
  };

  useEffect(() => {
    const urlArray = pathname.split("/");
    let page = urlArray[urlArray.length - 1];
    setPage(page);
  }, [pathname]);

  return (
    <>
      {isFetchWlList && <Loader />}
      <div className="main-content">
        <button
          className="sidebar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <span className="material-symbols-outlined fs-2 align-top">
            widgets
          </span>
        </button>
        <div
          className="sidebar offcanvas-lg offcanvas-end"
          id="offcanvasSidebar"
          tabIndex="-1"
        >
          <ul className="nav">
            <li
              className={`nav-item ${page === "resume-writer" ? "active" : ""}`}
              onClick={() =>
                logFirebaseEvent(`DASHBOARD_TAB_${WRITER_SCREEN.SIDEBAR}`)
              }
            >
              <NavLink to="/resume-writer" className="nav-link">
                <span
                  className="nav-span"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasSidebar"
                >
                  <span className="icon material-symbols-outlined">
                    dashboard
                  </span>
                  <span className="nav-link-text">Dashboard</span>
                </span>
              </NavLink>
            </li>

            <li
              className={`nav-item ${page === "my-clients" ? "active" : ""}`}
              onClick={() =>
                logFirebaseEvent(`MY_CLIENTS_TAB_${WRITER_SCREEN.SIDEBAR}`)
              }
            >
              <NavLink to="/resume-writer/my-clients" className="nav-link">
                <span
                  className="nav-span"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasSidebar"
                >
                  <span className="material-symbols-outlined">
                    supervisor_account
                  </span>
                  <span className="nav-link-text">My clients</span>
                </span>
              </NavLink>
            </li>

            <li
              className={`nav-item ${page === "share" ? "" : ""}`}
              onClick={handleCopyShareUrl}
            >
              <NavLink className="nav-link">
                <span
                  className="nav-span"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasSidebar"
                >
                  <span className="icon material-symbols-outlined">share</span>
                  <span className="nav-link-text">Share</span>
                </span>
              </NavLink>
            </li>

            {isShowAllCLientsTab() ? (
              <li
                className={`nav-item ${page === "all-clients" ? "active" : ""}`}
                onClick={() =>
                  logFirebaseEvent(`ALL_CLIENTS_TAB_${WRITER_SCREEN.SIDEBAR}`)
                }
              >
                <NavLink to="/resume-writer/all-clients" className="nav-link">
                  <span
                    className="nav-span"
                    data-bs-dismiss="offcanvas"
                    data-bs-target="#offcanvasSidebar"
                  >
                    <span className="icon material-symbols-outlined">
                      groups
                    </span>
                    <span className="nav-link-text">All clients</span>
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )}

            {WHITE_LABEL_TAB_ACCESS.includes(email) && (
              <li
                className={`nav-item ${page === "white-label" ? "active" : ""}`}
                onClick={() =>
                  logFirebaseEvent(`WL_TAB_${WRITER_SCREEN.SIDEBAR}`)
                }
              >
                <NavLink to="/resume-writer/white-label" className="nav-link">
                  <span
                    className="nav-span"
                    data-bs-dismiss="offcanvas"
                    data-bs-target="#offcanvasSidebar"
                  >
                    <span className="material-symbols-outlined">label</span>
                    <span className="nav-link-text">White Label</span>
                  </span>
                </NavLink>
              </li>
            )}

            {ALL_USER_TAB_ACCESS.includes(email) && (
              <li
                className={`nav-item ${page === "users" ? "active" : ""}`}
                onClick={() =>
                  logFirebaseEvent(`WL_TAB_${WRITER_SCREEN.SIDEBAR}`)
                }
              >
                <NavLink to="/resume-writer/users" className="nav-link">
                  <span
                    className="nav-span"
                    data-bs-dismiss="offcanvas"
                    data-bs-target="#offcanvasSidebar"
                  >
                    <span className="icon material-symbols-outlined">
                      group
                    </span>
                    <span className="nav-link-text">All User</span>
                  </span>
                </NavLink>
              </li>
            )}
            {ALL_USER_TAB_ACCESS.includes(email) && (
              <li
                className={`nav-item ${
                  page === "linkedin-users" ? "active" : ""
                }`}
                onClick={() =>
                  logFirebaseEvent(`WL_TAB_${WRITER_SCREEN.SIDEBAR}`)
                }
              >
                <NavLink
                  to="/resume-writer/linkedin-users"
                  className="nav-link"
                >
                  <span
                    className="nav-span"
                    data-bs-dismiss="offcanvas"
                    data-bs-target="#offcanvasSidebar"
                  >
                    <span className="icon material-symbols-outlined">link</span>
                    <span className="nav-link-text">LinkedIn Users</span>
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className="sidebar-content">{children}</div>
      </div>
    </>
  );
};

export default Sidebar;
