import { logEvent } from "firebase/analytics";

import { analytics } from "../firebase";

export const logFirebaseEvent = async (eventName, params) => {
  await logEvent(analytics, eventName, params ? { params } : {});
};

export const logFirebasePageName = async (screenName) => {
  await logEvent(analytics, "page_view", {
    page_title: screenName,
  });
};
