import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { isCustomResumeDomain } from "./utils/functions";

const root = ReactDOM.createRoot(document.getElementById("root"));
isCustomResumeDomain();

const clientId =
  "456415665255-1ii2djc2mhgkj9t8mjr48rqk0p4l88kb.apps.googleusercontent.com";

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={clientId}>
    <App />
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
