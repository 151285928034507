import { Slide } from "react-toastify";

const CLOUD_FUN_LOCAL_URL =
  "http://127.0.0.1:5001/theaihelper-f46d1/us-central1";
const CLOUD_FUN_PROD_URL =
  "https://us-central1-theaihelper-f46d1.cloudfunctions.net";

const CURRENT_YEAR = new Date().getFullYear();

const FILE_TYPE = {
  DOCX: "docx",
  DOC: "doc",
  DOCS: "docs",
  PDF: "pdf",
};

const EMBED_ID = {
  FOUR_WAYS: "8oyVxKWn7NY",

  SUMMARY_INTRO: "FMTovxU81Do",
  CUSTOMIZE_SUMMARY: "nGlFBIUxTho",
  ADD_BULLET_POINT_SUMMARY: "7uni7y55R40",

  EXPERIENCE: "JuQSwmKfehA",
  ADD_BULLET_POINT_EXPERIENCE: "7uni7y55R40",

  CORE_SKILLS: "GzIUkqaXtlw",
  TECHNICAL_SKILLS: "GzIUkqaXtlw",
  PROJECTS: "B1WrM6nqs88",
  EDUCATION: "HrtX5qIQZJs",
  CERTIFICATION: "hADptlgDmbg",
  HOME_PAGE: "_iCjhQr2nvM",
  HOME_PAGE_CUSTOMERESUME: "iIsuLErsC30",
  RESUME_SERVICES: "kyrF1Uowr7M",
};

const TOAST_CONTAINER_CONFIG = {
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  transition: Slide,
};

const TOAST_CONTAINER_CONFIG_RESUME = {
  autoClose: 15000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  transition: Slide,
};

const DASHBOARD_ITEMS = [
  {
    title: "Email Writer",
    description:
      "Give me a few points and I will write professional E-mail for you!",
    path: "/emailWriter",
  },
  {
    title: "Ai Interview Helper",
    description: "You want to prepare for the upcoming interview!?",
    path: "/interviewBuddy",
  },
  {
    title: "Content Writer",
    description:
      "Blog post, Tweet, LinkedIn Post, Article, Guide, Video Script, Webinar, Case Studies , White Paper",
    path: "/contentWriter",
  },
  {
    title: "Gift Finders",
    description: "Throw random thought and it will make a tweet for you!",
    path: "/giftFinder",
  },
  {
    title: "Make me a Tweet!",
    description:
      "Throw random thought and it will make a tweet for you! (Maybe reply to a tweet)",
  },
  {
    title: "Wingman",
    description: "I will write poem for you... I will giv you pick up lines...",
  },

  {
    title: "Amaxon/Etsey Product Description Writer",
    description: "Add Text here",
  },
  {
    title: "Summary for Content",
    description: "Give me a summary for long content",
  },
];

const RESUME_SECTION_LIST = [
  {
    id: 1,
    name: "Summary",
    checked: true,
    label: "recommended",
    value: "summary",
  },
  {
    id: 2,
    name: "Education",
    checked: true,
    label: "recommended",
    value: "education",
  },
  {
    id: 3,
    name: "Experience",
    checked: true,
    label: "recommended",
    value: "experience",
  },
  {
    id: 4,
    name: "Skills ",
    checked: true,
    label: "recommended",
    value: "skills",
  },
  {
    id: 5,
    name: "Certifications",
    checked: false,
    label: "",
    value: "certifications",
  },
  {
    id: 6,
    name: "Project",
    checked: false,
    label: "",
    value: "projects",
  },
];

const RESUME_DRAFT_TEMPLATE = {
  summary:
    "Experienced project manager with a strong track record of successfully managing complex projects on time and within budget. Skilled in leading cross-functional teams, mitigating risks, and communicating effectively with stakeholders.",
  experience: [
    {
      company: "ABC Corporation",
      title: "Project Manager",
      dates: `January ${CURRENT_YEAR - 2} - Present`,
      responsibilities:
        "Managed multiple projects for clients in the technology sector, including software development, infrastructure upgrades, and system integrations. Led cross-functional teams of up to 20 members and ensured timely project delivery within budget.",
      location: "New York, NY",
    },
  ],
  skills: {
    competencies: [
      "Execution",
      "Decision Making",
      "Communications",
      "Strategy Development",
      "Leadership",
    ],
    technical: [
      "Project management",
      "Budgeting and financial management",
      "Risk management",
      "Stakeholder management",
    ],
  },
  education: [
    {
      title: "Bachelor of Science in Business Administration",
      school: "XYZ University",
      location: "Boston, MA",
      dates: "January 2011 - February 2015",
      achievements:
        "I was responsible for writing code and create software programs and applications that meet the needs of users.",
    },
  ],
  projects: [
    {
      title: "ABC Software Development Project",
      organization: "NYU University",
      description:
        "Managed a software development project for a client in the financial services industry. Coordinated with a team of developers and designers to create a new trading platform with real-time market data and advanced analytics.",
      dates: "January 2015 - February 2016",
      url: "",
      file: "",
    },
  ],
  certifications: [
    {
      title: "Master Of Science",
      organization: "NYU University",
      acquiredYear: CURRENT_YEAR,
      location: "Chicago, IL",
      achievements:
        "I was responsible for writing code and create software programs and applications that meet the needs of users.",
    },
  ],
  involvement: [
    {
      organization: "Project Management Institute (PMI)",
      role: "Member",
      dates: "March 2016 - February 2018",
    },
  ],
};

const NEW_RESUME_TEMPLATE1 = {
  summary:
    "Experienced project manager with a strong track record of successfully managing complex projects on time and within budget. Skilled in leading cross-functional teams, mitigating risks, and communicating effectively with stakeholders.",
  basicInfo: {
    name: "Name",
    address: "Address",
    contactNumber: "9874563210",
    email: "name@gmail.com",
    linkedin: "https://www.linkedin.com/in/user-name/",
    position: "Manager",
  },
};

const NEW_RESUME_TEMPLATE2 = {
  skills: {
    competencies: ["", "", "", "", ""],
    technical: ["", "", "", ""],
  },
  education: [
    {
      title: "",
      school: "",
      location: "",
      dates: "",
      achievements: "",
      marks: "",
    },
  ],
  projects: [
    {
      title: "",
      organization: "",
      description: "",
      dates: "",
      url: "",
      file: "",
    },
  ],
  certifications: [
    {
      title: "",
      organization: "",
      acquiredYear: "",
      location: "",
      achievements: "",
    },
  ],
};

const NEW_RESUME_TEMPLATE3 = {
  experience: [
    {
      company: "ABC Corporation",
      title: "Project Manager",
      dates: `January ${CURRENT_YEAR - 2} - Present`,
      responsibilities:
        "Managed multiple projects for clients in the technology sector, including software development, infrastructure upgrades, and system integrations. Led cross-functional teams of up to 20 members and ensured timely project delivery within budget.",
      location: "New York, NY",
    },
  ],
};

const RESUME_FLAG = {
  ADD_EXPERIENCE: "addExperience",
  EXPERIENCE: "experience",
  ADD_SKILLS: "skills",
  SKILLS: "skills",
  COMPETENCIES_SKILLS: "COMPETENCIES SKILLS",

  ADD_EDUCATION: "addEducation",
  EDUCATION: "education",
  ADD_CERTIFICATIONS: "addCertifications",
  CERTIFICATION: "certification",

  ADD_PROJECTS: "addProjects",
  PROJECTS: "projects",
  SUMMARY: "summary",
};

const LOCATION_TYPES = ["Office", "Remote", "Hybrid"];

const MONTHS = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const MONTHS2 = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DAYS_TYPE1 = Array.from({ length: 29 }, (_, i) => i + 1);
const DAYS_TYPE2 = Array.from({ length: 30 }, (_, i) => i + 1);
const DAYS_TYPE3 = Array.from({ length: 31 }, (_, i) => i + 1);

const TOOLBAR_OPTIONS = [[{ list: "bullet" }, "bold"]];
const TOOLBAR_OPTIONS_NONE = [[]];

const HEAD_CONTENT = {
  title: `CareerClaw.com - Career Clarity, Career Mastery.`,
  description: "Instant Resume Enhancement in Seconds! (100% ATS Compliant)",
};

const SCREEN = {
  RESUME_DRAFT: "RESUME_DRAFT",
  RESUME_WRITER_DRAFT: "RESUME_WRITER_DRAFT",
  RESUME_WRITER_BASIC_INFO: "RESUME_WRITER_BASIC_INFO",
  CREATE_RESUME_SECTION: "CREATE_RESUME_SECTION",
  CREATE_WRITER_RESUME_SECTION: "CREATE_WRITER_RESUME_SECTION",
  CHOOSE_RESUME_CATEGORY: "CHOOSE_RESUME_CATEGORY",
  QUICK_GENERATE_RESUME: "QUICK_GENERATE_RESUME",
};

const RESUME_TEMPLATE_ID = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const DB_NAME = {
  CALENDLY_URL: "calendly-url",
  CALENDLY_URL_COACH: "calendly-url-coach",
  RESUME_WRITER: "resume-writer",

  WRITER_CLIENTS_RESUME: "clients-resume", // resume of writer's clients
  USER_RESUME: "resume", // resume of individual resume User
  WL_ADMIN_ACCESS_List: "wl-admin-access-list", // White Label Admin Access list
  USER_INFO: "user-info",
  ONBOARD_WRITERS: "onboard-clients", // list of onboarded writers
  API_COUNTER: "api-counter", // Count the API called
  CARRER_CHOOSING: "carrer-choosing", // Carrer choosing db
  COVER_LETTER: "cover-letter", // User's cover letter

  ALL_FEATURE_ACCESS_USER_LIST: "all-feature-access-user-list",
};

const REMOTE_CONFIG = {
  HOME_SCREEN_ORDER: "home_screen_order",
  HSO_BY_RS_FOR_BOTH_DOMAIN: "home_screen_order_by_RS_for_both_domain",
  HSO_BY_USD_FOR_CUSTOMRESUME: "home_screen_order_by_USD_for_CustomResume",
  HSO_BY_USD_FOR_CARRERCLAW_WL_IS_AMITPATEL:
    "home_screen_order_by_USD_for_CarrerClaw_wl_is_amitpatel",
  HSO_BY_USD_FOR_CARRERCLAW_WL_IS_NULL:
    "home_screen_order_by_USD_for_CarrerClaw_wl_is_null",
};

const LANGUAGE_OPTIONS = [
  { label: "Arabic", value: "Arabic" },
  { label: "Chinese", value: "Chinese" },
  { label: "Croatian", value: "Croatian" },
  { label: "Dutch", value: "Dutch" },
  { label: "English", value: "English" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Hindi", value: "Hindi" },
  { label: "Italian", value: "Italian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Spanish", value: "Spanish" },
  { label: "Turkish", value: "Turkish" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Walloon", value: "Walloon" },
];

const RESUME_FONTS_OPTIONS = [
  { label: "Arial", value: "Arial" },
  { label: "Calibri", value: "Calibri" },
  { label: "Cambria", value: "Cambria" },
  { label: "Helvetica", value: "Helvetica" },
  { label: "Times New Roman", value: "Times-Roman" },
];

const PLAN_TYPE = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

const STRIPE_ROLE = {
  STARTER_MONTHLY: "starterMonthly",
  PRO_MONTHLY: "proMonthly",
  ENTERPRISE_MONTHLY: "enterpriseMonthly",
  STARTER_YEARLY: "starterYearly",
  PRO_YEARLY: "proYearly",
  ENTERPRISE_YEARLY: "enterpriseYearly",
  PDF_DOCX_STARTER_MONTHLY: "pdf-docx-starter-monthly",
  PDF_DOCX_STARTER_YEARLY: "pdf-docx-starter-yearly",
};

const STRIPE_ROLE_PLAN_OPTIONS = [
  STRIPE_ROLE.STARTER_MONTHLY,
  STRIPE_ROLE.PRO_MONTHLY,
  STRIPE_ROLE.ENTERPRISE_MONTHLY,
  STRIPE_ROLE.STARTER_YEARLY,
  STRIPE_ROLE.PRO_YEARLY,
  STRIPE_ROLE.ENTERPRISE_YEARLY,
];

const CREATE_SUMMARY_TAB = {
  INTRO: "intro",
  QUESTIONS: "questions",
  SUMMARY: "summary",
};

const RESUME_CREATE_TYPE = {
  QUICK: "quick",
  TIME_TAKEN: "timeTaken",
};

const SELECT_EXPERIENCE_CATEGORY = {
  ENTRY_LEVEL: "Entry-Level Professionals",
  MID_LEVEL: "Mid-Level Professionals",
  SENIOR_LEVEL: "Senior-Level Professionals",
  CARRER_CHANGE: "Career Changers",
};

const CORE_SKILL_OPTIONS = [
  {
    value: "Adaptability",
    label: "Adaptability",
  },
  {
    value: "Communication",
    label: "Communication",
  },
  {
    value: "Creativity",
    label: "Creativity",
  },
  {
    value: "Critical thinking",
    label: "Critical thinking",
  },
  {
    value: "Decision making",
    label: "Decision making",
  },
  {
    value: "Emotional intelligence",
    label: "Emotional intelligence",
  },
  {
    value: "Leadership",
    label: "Leadership",
  },
  {
    value: "Problem solving",
    label: "Problem solving",
  },
  {
    value: "Team work",
    label: "Team work",
  },
  {
    value: "Time management",
    label: "Time management",
  },
];

const RESUME_GENERATE_THROUGH = {
  STEP_BY_STEP: "step-by-step",
  UPLOADED_RESUME: "upload-existing-resume",
  LINKEDIN_PDF: "linkedIn-pdf",
  QUICK_TEMPLATE: "quick-template",
  CLIENT_SUBMIT_FORM: "client-submit-form",
};

const WL = {
  SAURABH: "saurabh",
  FEMALE_AND_FINANCE: "femalesandfinance",
};

const WL_LOGO = {
  SAURABH:
    "https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/whitelabel%2Flogo%2Flogo_saurabh.png?alt=media&token=1a39ee76-e085-42b7-90ac-b97586b47c7a",
  FEMALE_AND_FINANCE:
    "https://firebasestorage.googleapis.com/v0/b/theaihelper-f46d1.appspot.com/o/whitelabel%2Flogo%2Flogo_femalesandfinance1.png?alt=media&token=8645526d-b40e-4a14-862f-38b92ab520c3",
};

// For Firebase Events
const RESUME_SCREEN = {
  CREATE_RESUME: "SELECT_RESUME_OPTION_PAGE",
  FROM_LINKEDIN_RESUME: "CREATE_RESUME_FROM_LINKEDIN_PAGE",
  FROM_EXISTING_RESUME: "CREATE_RESUME_FROM_EXISTING_PAGE",

  SELECT_EXP_CATEGORY: "SELECT_EXP_CAT_RESUME_PAGE",
  BASIC_INFO: "BASICINFO_RESUME_PAGE",
  SUMMARY: "SUMMARY_RESUME_PAGE",
  EXP: "EXP_RESUME_PAGE",
  CORE_SKILLS: "CORE_SKILLS_RESUME_PAGE",
  TECHNICAL_SKILLS: "TECHNICAL_SKILLS_RESUME_PAGE",
  PROJECTS: "PROJECTS_RESUME_PAGE",
  CERTIFICATION: "CERTIFICATION_RESUME_PAGE",
  EDUCATION: "EDUCATION_RESUME_PAGE",
  SHOW_RESUME: "SHOW_RESUME_PAGE",
  SUBSCRIPTION: "SUBSCRIPTION_PAGE",
};

// For Firebase Events
const WRITER_SCREEN = {
  HEADER: "WRITER_HEADER",
  SIDEBAR: "WRITER_SIDERBAR",
  DASHBOARD: "WRITER_DASHBOARD_PAGE",
  CLIENTS: "WRITER_CLIENTS_SCREEN",
  SELECT_CLIENT_RESUME_OPT: "SELECT_CLIENT_RESUME_OPTION_PAGE",
  FROM_LINKEDIN_RESUME: "CREATE_CLIENT_RESUME_FROM_LINKEDIN_PAGE",
  FROM_EXISTING_RESUME: "CREATE_CLIENT_RESUME_FROM_EXISTING_PAGE",

  SELECT_EXP_CATEGORY: "SELECT_EXP_CAT_CLIENT_RESUME_PAGE",
  BASIC_INFO: "BASICINFO_CLIENT_RESUME_PAGE",
  SUMMARY: "SUMMARY_CLIENT_RESUME_PAGE",
  EXP: "EXP_CLIENT_RESUME_PAGE",
  CORE_SKILLS: "CORE_SKILLS_CLIENT_RESUME_PAGE",
  TECHNICAL_SKILLS: "TECHNICAL_SKILLS_CLIENT_RESUME_PAGE",
  PROJECTS: "PROJECTS_CLIENT_RESUME_PAGE",
  CERTIFICATION: "CERTIFICATION_CLIENT_RESUME_PAGE",
  EDUCATION: "EDUCATION_CLIENT_RESUME_PAGE",
  SHOW_RESUME: "SHOW_CLIENT_RESUME_PAGE",

  ALL_CLIENTS: "ALL_CLIENTS_PAGE",
};

const UID = {
  amit: "jLxbW2aUW1Vj5DtTkfZR2IImYhh1",
};

const MAX_FREE_API_COUNT = 10;
const INITIAL_IS_SHOW_LOCATION = true;

export default {
  FILE_TYPE,
  EMBED_ID,
  CURRENT_YEAR,
  TOAST_CONTAINER_CONFIG,
  TOAST_CONTAINER_CONFIG_RESUME,
  DASHBOARD_ITEMS,
  RESUME_SECTION_LIST,
  RESUME_DRAFT_TEMPLATE,
  NEW_RESUME_TEMPLATE1,
  NEW_RESUME_TEMPLATE2,
  NEW_RESUME_TEMPLATE3,
  RESUME_FLAG,
  LOCATION_TYPES,
  MONTHS,
  DAYS_TYPE1,
  DAYS_TYPE2,
  DAYS_TYPE3,
  TOOLBAR_OPTIONS,
  TOOLBAR_OPTIONS_NONE,
  HEAD_CONTENT,
  SCREEN,
  RESUME_TEMPLATE_ID,
  DB_NAME,
  REMOTE_CONFIG,
  LANGUAGE_OPTIONS,
  RESUME_FONTS_OPTIONS,
  CLOUD_FUN_LOCAL_URL,
  CLOUD_FUN_PROD_URL,
  PLAN_TYPE,
  STRIPE_ROLE,
  STRIPE_ROLE_PLAN_OPTIONS,
  SELECT_EXPERIENCE_CATEGORY,
  RESUME_CREATE_TYPE,
  CREATE_SUMMARY_TAB,
  CORE_SKILL_OPTIONS,
  MONTHS2,
  RESUME_GENERATE_THROUGH,
  WL,
  WL_LOGO,
  RESUME_SCREEN,
  WRITER_SCREEN,
  UID,
  MAX_FREE_API_COUNT,
  INITIAL_IS_SHOW_LOCATION,
};
