import { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { remoteConfig } from "../../firebase";
import { AuthContext } from "../../Contexts/AuthContext";

const useFetchHeaderTabVisibility = () => {
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;

  const [isLoading, setIsLoading] = useState(false);
  const [isGotResponse, setIsGotResponse] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState({
    isShowResumeTab: false,
    isShowCoverLetterTab: false,
    isShowResumeWriterTab: false,
    isShowAiToolsTab: false,
  });

  const fetchConfigData = async () => {
    setIsLoading(true);
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        const value = remoteConfig.getValue("header_tab_visibility");
        const response = value._value;

        if (!isEmpty(response)) {
          setIsShowHeader(JSON.parse(response));
          setIsGotResponse(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (uid) {
      fetchConfigData();
    }
  }, [uid]);

  return {
    isLoading,
    isGotResponse,
    isShowHeader,
  };
};

export default useFetchHeaderTabVisibility;
