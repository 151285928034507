import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { whiteLabelList } from "../../helper/whitelabel/whiteLabelList";

const useFetchWlNameLogoComName = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [wlCompanyName, setWlCompanyName] = useState("");
  const [wlName, setWlName] = useState("");
  const [wlCompanyLogo, setWlCompanyLogo] = useState("");
  const [wlYoutubeEmbedId, setWlYoutubeEmbedId] = useState("");
  const [wlThemeColor, setThemeColor] = useState("");
  const [wlEmail, setWlEmail] = useState("");

  const fetchWriterClientsResume = async () => {
    setIsLoading(false);
    const whiteLabel = localStorage.getItem("whiteLabel") || "";

    if (!isEmpty(whiteLabelList)) {
      const obj = whiteLabelList.find(
        (element) => element.whiteLabel === whiteLabel
      );
      if (!isEmpty(obj)) {
        setWlCompanyName(get(obj, "companyName", ""));
        setWlCompanyLogo(get(obj, "companyLogo", ""));
        setWlName(get(obj, "whiteLabel", ""));
        setThemeColor(get(obj, "themeColor", ""));
        setWlYoutubeEmbedId(get(obj, "youtubeEmbedId", ""));
        setWlEmail(get(obj, "email", ""));
      }
    }
  };

  useEffect(() => {
    fetchWriterClientsResume();
  }, []);

  return {
    isLoading,
    wlCompanyName,
    wlCompanyLogo,
    wlName,
    wlThemeColor,
    wlYoutubeEmbedId,
    wlEmail,
  };
};

export default useFetchWlNameLogoComName;
