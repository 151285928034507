import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import { resumeRebrandPlanCS } from "../../Stripe/checkout-session/premiumServiceCS";
import { useAuth } from "../../Contexts/AuthContext";
import Loader from "../../Components/common/Loader";
import accessConstants from "../../utils/accessConstants";

const { CALENDLY_URL } = accessConstants;

const ExpertOption = () => {
  const { currentUser } = useAuth();
  const uid = get(currentUser, "uid", "");
  const navigate = useNavigate();
  const location = useLocation();
  const { isResumeExist } = location.state || {};
  const [loading, setIsLoading] = useState(false);

  const CardComponent = ({ title, description, buttonText, buttonAction }) => {
    return (
      <div className="expCard ps-4">
        <p className="fs-2 text-primary fw-bold mb-1">{title}</p>
        <p className="fs-5 text-primary">{description}</p>
        <button onClick={buttonAction}>{buttonText}</button>
      </div>
    );
  };

  const handleBookNow = () => {
    window.open(CALENDLY_URL.AMIT, "_blank", "noreferrer");
  };

  const handleHireAmit = () => {
    window.open(
      "https://buy.stripe.com/7sI6r86P506n9FKdQY",
      "_blank",
      "noreferrer"
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container pt-3">
        <CardComponent
          title="DIY (Do it Yourself)"
          description="Want to take control? Follow my easy step-by-step guide to build your resume."
          buttonText="Get Started"
          buttonAction={() =>
            navigate(isResumeExist ? "/select-resume-type" : "/login")
          }
        />
        <CardComponent
          title="Hire Amit for Your Resume"
          description="Let me handle it for you! I'll craft a professional resume tailored to your needs."
          buttonText="Hire for $299"
          buttonAction={handleHireAmit}
        />
        <CardComponent
          title="Talk to Amit - Free Call"
          description="Not sure where to start? Let’s talk! Schedule a free consultation, and I’ll guide you through the process."
          buttonText="Book Free Call"
          buttonAction={handleBookNow}
        />
      </div>
    </>
  );
};

export default ExpertOption;
