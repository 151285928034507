const ALL_CLIENTS_TAB_ACCESS = ["amitpatel321@gmail.com", "testing1@gmail.com"];
const WHITE_LABEL_TAB_ACCESS = ["amitpatel321@gmail.com", "testing1@gmail.com"];
const ALL_USER_TAB_ACCESS = ["amitpatel321@gmail.com", "testing1@gmail.com"];
const HEADER_TAB_ACCESS = ["amitpatel321@gmail.com", "testing1@gmail.com"];
const SHOW_SERVICES_PRICES = ["amitpatel321@gmail.com", "testing1@gmail.com"];
const MANAGE_ALL_FEATURE_FREE_DB_ACCESS = [
  "amitpatel321@gmail.com",
  "testing1@gmail.com",
];

const CALENDLY_URL = {
  AMIT: "https://calendly.com/amit_patel/free",
};

const LOOM_ID = {
  HOME: "979251480e7341bbbe64dadd9e1a5a79",
};

const CUSTOM_CARRER_CLAW_URL = [
  "https://www.careerclaw.com",
  "http://www.careerclaw.com",
  "https://careerclaw.com",
  "http://careerclaw.com",
];
const CUSTOM_CUSTOM_RESUME_URL = [
  "https://www.customresume.us",
  "http://www.customresume.us",
  "https://customresume.us",
  "http://customresume.us",
];

const LINKEDIN_URL = {
  AMIT_CUSTOM_RESUME: "https://www.linkedin.com/in/armspatel/",
  AMIT: "https://www.linkedin.com/in/aamitmit/",
};

export default {
  ALL_CLIENTS_TAB_ACCESS,
  WHITE_LABEL_TAB_ACCESS,
  ALL_USER_TAB_ACCESS,
  CALENDLY_URL,
  LOOM_ID,
  HEADER_TAB_ACCESS,
  CUSTOM_CARRER_CLAW_URL,
  CUSTOM_CUSTOM_RESUME_URL,
  LINKEDIN_URL,
  MANAGE_ALL_FEATURE_FREE_DB_ACCESS,
  SHOW_SERVICES_PRICES,
};
