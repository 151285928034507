import React from "react";
import useFetchWlNameLogoComName from "../../hooks/whiteLabel/useFetchWlNameLogoComName";
import { isCustomResumeDomain } from "../../utils/functions";
import CarrerClawLogo from "../../assets/image/svg/carrer-claw.svg";
import CustomResumeLogo from "../../assets/image/Custom-resume-logo.png";

const HeaderLogo = () => {
  const { wlCompanyLogo } = useFetchWlNameLogoComName();

  const onImageError = (e) => {
    e.target.src = CarrerClawLogo;
  };

  const getSrc = () => {
    if (isCustomResumeDomain()) {
      return CustomResumeLogo;
    } else if (wlCompanyLogo) {
      return wlCompanyLogo;
    } else {
      return CarrerClawLogo;
    }
  };

  return (
    <>
      <img
        loading="lazy"
        src={getSrc()}
        alt="Logo"
        style={{ height: 48, width: isCustomResumeDomain() ? 170 : 150 }}
        onError={onImageError}
      />
    </>
  );
};

export default HeaderLogo;
