import isEmpty from "lodash/isEmpty";
import constants from "./constant";
import accessConstants from "./accessConstants";

const { CLOUD_FUN_PROD_URL, WL, CLOUD_FUN_LOCAL_URL } = constants;
const { CUSTOM_CARRER_CLAW_URL, CUSTOM_CUSTOM_RESUME_URL, LINKEDIN_URL } =
  accessConstants;

export const getCloudFunctionUrl = () => {
  const cloudFunctionUrl = CLOUD_FUN_PROD_URL;
  // const cloudFunctionUrl = CLOUD_FUN_LOCAL_URL;
  return cloudFunctionUrl;
};

export const setHtmlListToPlainText = (html) => {
  return html
    .replaceAll("<ul>", "")
    .replaceAll("</ul>", "")
    .replaceAll("<li>", "")
    .replaceAll("</li>", ".")
    .replaceAll("..", ".")
    .replaceAll(".", ". ")
    .replaceAll("  ", " ");
};

export const setPlainTextToHtmlList = (text) => {
  let finalText = "";
  let res = "";

  if (!isEmpty(text) && typeof text === "string") {
    const textArr = text.split(". ").filter((item) => item.length !== 0);
    textArr.forEach((item) => {
      if (item.endsWith(".")) {
        res += `<li>${item}</li>`;
      } else {
        res += `<li>${item}.</li>`;
      }
    });
  }
  finalText = `<ul>${res}</ul>`;
  return finalText;
};

export const convertHTMLToPlainText = (html) => {
  // Create a new div element
  var tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

export const getFilteredQuestions = (questionList = []) => {
  const values = [...questionList];
  let filteredValues = [];

  if (!isEmpty(questionList)) {
    values.forEach((item, index) => {
      if (index === 0) {
        filteredValues.push({ ...item, isOpen: true });
      } else {
        filteredValues.push({ ...item, isOpen: true });
      }
    });
  }

  return filteredValues;
};

export const removeAnswerFromQuestions = (questionList) => {
  const values = [...questionList];
  let filteredValues = [];

  if (!isEmpty(questionList)) {
    values.forEach((item) => {
      filteredValues.push({ ...item, answer: "" });
    });
  }

  return filteredValues;
};

export const getWriterUid = () => {
  const defaultId = "jLxbW2aUW1Vj5DtTkfZR2IImYhh1";
  const url = window.location.href;
  const urlArr = url?.split("?");
  let resumeWriterId = defaultId;

  if (urlArr && urlArr.length) {
    const paramsArr = urlArr[1];
    const paramsValueArr = paramsArr?.split("&");

    if (paramsValueArr && paramsValueArr.length) {
      const filteredArr = paramsValueArr.filter((item) =>
        item.includes("writer=")
      );

      if (filteredArr && filteredArr.length) {
        const writerArr = filteredArr[0].split("=");

        resumeWriterId = writerArr[1] || defaultId;
      }
    }
  }
  localStorage.setItem("resumeWriterId", resumeWriterId);
  return resumeWriterId;
};

export const getWhiteLabelParams = () => {
  const url = window.location.href;
  const urlArr = url?.split("?");
  let whiteLabel = "";
  let isWlKeyPresent = false;

  if (urlArr && urlArr.length) {
    const paramsArr = urlArr[1];
    const paramsValueArr = paramsArr?.split("&");

    if (paramsValueArr && paramsValueArr.length) {
      const filteredArr = paramsValueArr.filter((item) => item.includes("wl="));

      if (filteredArr && filteredArr.length) {
        const whiteLabelArr = filteredArr[0].split("=");

        isWlKeyPresent = whiteLabelArr[0] ? true : false;
        whiteLabel = whiteLabelArr[1] || "";
      }
    }
  }

  if (isWlKeyPresent && !whiteLabel) {
    localStorage.setItem("whiteLabel", whiteLabel || "");
  } else {
    const whitelabelLocalStorageValue = localStorage.getItem("whiteLabel");
    whiteLabel = whiteLabel ? whiteLabel : whitelabelLocalStorageValue;
    localStorage.setItem("whiteLabel", whiteLabel);
  }

  return whiteLabel;
};

export const getBrandText = () => {
  const whiteLabel = localStorage.getItem("whiteLabel");

  if (whiteLabel === WL.FEMALE_AND_FINANCE) {
    return "FemalesAndFinance";
  } else {
    return "CareerClaw ";
  }
};

export const getResponseMessageForSubs = () => {
  const url = window.location.href;
  const urlArr = url?.split("?");
  let response = "";

  if (urlArr && urlArr.length) {
    const paramsArr = urlArr[1];
    const paramsValueArr = paramsArr?.split("&");

    if (paramsValueArr && paramsValueArr.length) {
      const filteredArr = paramsValueArr.filter((item) =>
        item.includes("response=")
      );

      if (filteredArr && filteredArr.length) {
        const responseArr = filteredArr[0].split("=");

        response = responseArr[1] || "";
      }
    }
  }
  return response;
};

export const isCustomResumeDomain = () => {
  const host = window.location.host;
  // const host = "customresume.us"; //TODO: uncomment this for testing

  if (host === "customresume.us") {
    document.getElementById("favlogo").setAttribute("href", "./customfav.ico");
    document.getElementById("logo").setAttribute("href", "./logo192.png");
    document.getElementById("page-title").textContent =
      "Custom Resume - Career Clarity, Career Mastery.";
  } else {
    document.getElementById("logo").setAttribute("href", "./logo193.png");
    document.getElementById("favlogo").setAttribute("href", "./favicon.ico");
    document.getElementById("page-title").textContent =
      "CareerClaw.com - Career Clarity, Career Mastery.";
  }

  return host === "customresume.us";
};

export const getLinkedInUrl = () => {
  return isCustomResumeDomain()
    ? LINKEDIN_URL.AMIT_CUSTOM_RESUME
    : LINKEDIN_URL.AMIT;
};

export const parseHtmlForTags = (
  stringToParse,
  tagStyleObj = {
    strong: { fontFamily: "Arial-Bold" },
  }
) => {
  const tagsToFind = Object.keys(tagStyleObj);

  const validURL = `[A-Za-z0-9-_.!~*'()/:?#&=[\\]]*`;

  const reOpeningTags = "<"
    .concat(tagsToFind.reduce((acc, t) => acc.concat("|").concat(t), "(b"))
    .concat(`)\\s?([a-zA-Z]+="${validURL}")*>`);
  const reMiddle = "(.*?)";
  const reClosingTags = "</"
    .concat(tagsToFind.reduce((acc, t) => acc.concat("|").concat(t), "(b"))
    .concat(")>");
  const re = reOpeningTags.concat(reMiddle).concat(reClosingTags);

  const matches = stringToParse?.match(new RegExp(re, "g")) || [];

  const elements = [];
  let tempString = stringToParse;

  // for each match, search the string for the match, and split the string into 3 parts
  //    before the match
  //    the match
  //    after the match
  //  Repeat the process for the string after the match
  matches.forEach((m) => {
    const tagToSearch = m;

    const indexOfMatch = tempString.indexOf(tagToSearch);
    const s = [
      tempString.slice(0, indexOfMatch),
      tempString.slice(indexOfMatch, indexOfMatch + tagToSearch.length),
      tempString.slice(indexOfMatch + tagToSearch.length),
    ];

    const match = tagToSearch.match(new RegExp(re, ""));

    if (!match) return;

    const tag = match[1];

    const attrs = {};
    if (match[2]) {
      const matches = match[2].match(
        new RegExp(`([a-zA-Z]+)="(${validURL})"`, "")
      );
      if (matches) {
        const [, attrKey, attrValue] = matches;
        attrs[attrKey] = attrValue;
      }
    }

    const styleToApply = tagStyleObj[tag];
    elements[elements.length] = { style: {}, child: s[0] };
    elements[elements.length] = {
      name: tag,
      attributes: attrs,
      style: styleToApply,
      child: match[3],
    };
    tempString = s[2] || "";
  });

  if (tempString && tempString.length > 0) {
    elements[elements.length] = {
      style: {},
      child: tempString,
    };
  }
  return elements;
};
