import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import { toast } from "react-toastify";

import HeaderLogo from "../common/HeaderLogo";
import { useAuth } from "../../Contexts/AuthContext";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const resumeWriterId = get(location, "state.resumeWriterId", "");
  const [page, setPage] = useState("");

  const handleLogout = async () => {
    const givenResumeWriterId = localStorage.getItem("resumeWriterId");
    const whiteLabel = localStorage.getItem("whiteLabel");

    await logout();
    localStorage.clear();
    localStorage.setItem("whiteLabel", whiteLabel);

    if (givenResumeWriterId) {
      if (whiteLabel) {
        navigate(
          `/submit-information/?writer=${resumeWriterId}&wl=${whiteLabel}`
        );
      } else {
        navigate(`/submit-information/?writer=${resumeWriterId}`);
      }
    } else {
      if (whiteLabel) {
        navigate(`/submit-information/?wl=${whiteLabel}`);
      } else {
        navigate("/submit-information");
      }
    }
  };

  useEffect(() => {
    const urlArray = pathname.split("/");
    let page = urlArray[urlArray.length - 1];
    setPage(page);
  }, [pathname]);

  const handleTabClick = () => {
    toast.warn("Please click Save & Next, Back or Skip button");
  };

  return (
    <div className="submit-info-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a
            className="navbar-brand pointer"
            onClick={() => {
              navigate("/submit-information/select-experience-level", {
                state: { resumeWriterId },
              });
            }}
          >
            <HeaderLogo />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggle"
            aria-controls="navbarToggle"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarToggle">
            <ul className="navbar-nav mx-auto my-3 my-lg-0">
              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "basic-info" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // TODO: will remove later if not required (saurabh)
                  // onClick={() => {
                  //   navigate("/submit-information/basic-info", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Contact Info
                </a>
              </li>
              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background: page === "summary" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/summary", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Summary
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "experience" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/experience", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Experience
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "core-skills" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/core-skills", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Core Skills
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "technical-skills" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/technical-skills", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Technical Skills
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background: page === "projects" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/projects", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Projects
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "certifications" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/certifications", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Certifications
                </a>
              </li>

              <li
                className="nav-item"
                hidden={[
                  "submit-information",
                  "select-experience-level",
                  "",
                ].includes(page)}
              >
                <a
                  role="button"
                  className="nav-link"
                  style={{
                    color: "#000000",
                    background:
                      page === "education" ? "#FFFFFF" : "transparent",
                    borderRadius: "100px",
                  }}
                  onClick={handleTabClick}
                  // onClick={() => {
                  //   navigate("/submit-information/education", {
                  //     state: { resumeWriterId },
                  //   });
                  // }}
                >
                  Education
                </a>
              </li>
            </ul>

            <div className="d-flex mb-3 mb-lg-0">
              {currentUser?.isAnonymous && (
                <>
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={handleLogout}
                  >
                    Log Out
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
