import isEmpty from "lodash/isEmpty";
import constants from "../../utils/constant";

const { WL } = constants;
const textColorCode = {
  default: "#000000",
  active: "#6358DC",
  femaleAndFinance: "#CD007A",
};

export const isResumeWritingStudioHide = () => {
  const whiteLabel = localStorage.getItem("whiteLabel");

  if (whiteLabel === WL.FEMALE_AND_FINANCE) {
    return true;
  } else {
    return false;
  }
};

export const isWhitelabelFemaleAndFinance = () => {
  return WL.FEMALE_AND_FINANCE === localStorage.getItem("whiteLabel");
};

export const isWhiteLabelInUrl = () => {
  const whiteLabel = localStorage.getItem("whiteLabel") || "";

  if (whiteLabel && whiteLabel !== "null") {
    return true;
  } else {
    return false;
  }
};
// Button and Text Class
export const getButtonPrimaryClass = () => {
  const whiteLabel = localStorage.getItem("whiteLabel");

  if (whiteLabel === WL.FEMALE_AND_FINANCE) {
    return "btn-primary-femalesandfinance";
  } else {
    return "btn-primary";
  }
};

export const getTextPrimaryClass = () => {
  const whiteLabel = localStorage.getItem("whiteLabel");

  if (whiteLabel === WL.FEMALE_AND_FINANCE) {
    return "text-femalesandfinance";
  } else {
    return "text-primary";
  }
};

// Header text-color class
export const getHomeTabColor = (page, wlThemeColor) => {
  if (!page) {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getResumeTabColor = (
  page,
  isWlFemaleAndFinance,
  pageList = []
) => {
  if (
    ["draft", "select-resume-type"].includes(page) &&
    !pageList.includes("resume-writer")
  ) {
    if (isWlFemaleAndFinance) {
      return textColorCode.femaleAndFinance;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getResumeWriterTabColor = (pageList = [], wlThemeColor) => {
  if (
    pageList.includes("resume-writer") ||
    pageList.includes("clients") ||
    pageList[1] === "client"
  ) {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getCoverLetterTabColor = (page, wlThemeColor) => {
  if (page === "cover-letter") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getContactUsTabColor = (page, wlThemeColor) => {
  if (page === "contact") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getBlogTabColor = (page, wlThemeColor) => {
  if (page === "blog" || page === "blog-content") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getCoursesTabColor = (page, wlThemeColor) => {
  if (page === "courses") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getCarrerChoosingTabColor = (
  page,
  wlThemeColor,
  pageList = []
) => {
  if (
    page === "carrer-choosing" ||
    (pageList.includes("carrer-choosing") && pageList.includes("result"))
  ) {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getServicesTabColor = (page, wlThemeColor) => {
  if (page === "services") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getTestimonialsTabColor = (page, wlThemeColor) => {
  if (page === "testimonials") {
    if (wlThemeColor) {
      return wlThemeColor;
    } else {
      return textColorCode.active;
    }
  } else {
    return textColorCode.default;
  }
};

export const getIsAllQuestionAnswered = (questions = []) => {
  let count = 0;

  if (!isEmpty(questions)) {
    questions.forEach((item) => {
      if (isEmpty(item?.answer?.trim())) {
        count = count + 1;
      }
    });
  }

  return count ? false : true;
  // return true;
};

export const getAnswerString = (questions = []) => {
  let answerString = "";

  if (!isEmpty(questions)) {
    questions.forEach((item) => {
      if (!isEmpty(item?.answer?.trim())) {
        answerString =
          answerString + " " + `${item.question}: ${item?.answer},`;
        answerString = answerString.replaceAll("::", ":").replaceAll("?:", ":");
      }
    });
  }
  return answerString;
};
