import React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "react-error-boundary";
import CustomHead from "./Components/common/CustomHead";
import Routers from "./Routers";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/style.css";
import "./Styles/resume-writer-draft.css";
import "./Styles/amitCard.css";
import constant from "./utils/constant";
import { clarity } from "react-microsoft-clarity";

// Start seeing data on the Clarity dashboard with your id
if (process.env.NODE_ENV === "production") clarity.init("nsg6uadw7m");

const { TOAST_CONTAINER_CONFIG } = constant;

function fallbackRender({ error }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "150px",
      }}
      role="alert"
    >
      <div>
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    </div>
  );
}

const App = () => {
  return (
    <>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <HelmetProvider>
          <ToastContainer {...TOAST_CONTAINER_CONFIG} />
          <CustomHead />
          <Router>
            <Routers />
          </Router>
        </HelmetProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
