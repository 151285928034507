import React, { useState } from "react";
import { logFirebaseEvent } from "../../utils/analytics";

const TestimonialsCarousel = ({ Edmond, Sushant, Joseph, LinkedInBlue }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };
  const testimonials = [
    {
      img: Edmond,
      quote: `"Amit's got a knack for career coaching that really sets him apart. With hands-on experience in hiring for big names like Macy's and CBS, he knows the game from the inside. This unique angle means he can give tips that aren't just theoretical but based on what big companies actually want. He's clued into the ins and outs of corporate hiring, so with him, you're getting insights that can really make a difference. Think of Amit not just as a coach but as your industry insider buddy. Teaming up with him? That's a smart move for your career."`,
      name: "Edmond McLaughlin",
      title: "Digital Services Expert (Engineering)",
    },
    {
      img: Joseph,
      quote: `"Amit stands out as a beacon of proficiency in the realm of career coaching. His adept understanding of industry dynamics combined with his personalized approach makes him invaluable to anyone seeking to elevate their professional journey. He masterfully guides individuals with actionable insights tailored to their unique career aspirations. His genuine passion and commitment to helping others achieve their goals are evident in the results he consistently delivers. Anyone fortunate enough to benefit from Amit's guidance will undoubtedly find themselves better positioned for success."`,
      name: "Joseph Toyoshima",
      title: "Director of Digital Analytics",
    },
    {
      img: Sushant,
      quote: `"Amit helped review my resume and re-word sections to highlight my experience in a better and readable format. I am extremely pleased with the new resume and excited to start applying for jobs with Amit's help. His expertise offered a second set of eyes in pointing out resume edits. By making the suggested edits in my resume, it places my experience, accomplishments and key results in the forefront. I would definitely recommend Amit's services in your career transition journey."`,
      name: "Sushant Arora",
      title: "Product Manager",
    },
  ];

  return (
    <section className="testimonials">
      <h3 className="text-white fs-1 ">Client Testimonials</h3>
      <p className="text-white fs-3 ">
        Hear from People Who Landed Their Dream Jobs with My Help
      </p>

      <div className="testimonial-cards container">
        {testimonials.map((testimonial, index) => {
          let position = "hidden";

          if (index === activeIndex) position = "center";
          else if (index === (activeIndex + 1) % testimonials.length)
            position = "right";
          else if (
            index ===
            (activeIndex - 1 + testimonials.length) % testimonials.length
          )
            position = "left";

          return (
            <div
              key={index}
              className={`testimonial-card ${position}`}
              onClick={() => handleCardClick(index)}
            >
              <img
                src={testimonial.img}
                alt={testimonial.name}
                width={120}
                className="rounded-pill mb-3"
              />
              <p className="fs-5">{testimonial.quote}</p>
              <p className="fs-14 mt-3">– {testimonial.name}</p>
              <p className="fs-14">{testimonial.title}</p>
            </div>
          );
        })}
      </div>

      <a
        href="https://www.linkedin.com/company/careerclaw/"
        className="join-btn"
        rel="noreferrer"
        target="_blank"
        onClick={() => {
          logFirebaseEvent("EVERYTHING_FREE_JOIN_US_BTN");
        }}
      >
        Join Us
        <img
          src={LinkedInBlue}
          alt="plus_icon"
          height={24}
          className="rounded ms-1 mt-n1"
        />
      </a>
    </section>
  );
};

export default TestimonialsCarousel;
