import React from "react";
import { useNavigate } from "react-router-dom";
import { logFirebaseEvent } from "../../utils/analytics";
import UserIcon from "../../assets/image/svg/profile-icon.svg";

const HeaderPopup = ({ handleUserLogout, email }) => {
  const navigate = useNavigate();

  const handleClickSubscription = () => {
    logFirebaseEvent("SUBSRIPTION_BTN_MAIN_HEADER");
    navigate("/subscription");
  };

  return (
    <div className="dropdown">
      <div
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          loading="lazy"
          src={UserIcon}
          alt="User-icon"
          width={34}
          height={34}
        />
      </div>

      <ul
        className="dropdown-menu dropdown-menu-start dropdown-menu-lg-end mt-3"
        aria-labelledby="dropdownMenuLink"
      >
        <li>
          <span className="dropdown-item-text">{email}</span>
        </li>
        <li>
          <a
            className="dropdown-item"
            role="button"
            onClick={handleClickSubscription}
          >
            Subscription
          </a>
        </li>
        <li>
          <a className="dropdown-item" role="button" onClick={handleUserLogout}>
            Log Out
          </a>
        </li>
      </ul>
    </div>
  );
};

HeaderPopup.defaultProps = {
  email: "",
  handleUserLogout: () => {},
};

export default HeaderPopup;
