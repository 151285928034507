import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getBlogTabColor,
  getTestimonialsTabColor,
} from "../../helper/common/commonHelper";
import { logFirebaseEvent } from "../../utils/analytics";
import accessConstants from "../../utils/accessConstants";

const { HEADER_TAB_ACCESS } = accessConstants;

const AiToolsHeaderDropdown = ({
  page,
  isShowHeader,
  wlThemeColor,
  openDropdown,
  setOpenDropdown,
  setOpenPopup,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const email = currentUser?.email;

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
    setOpenPopup(false);
  };

  const handleClickTestimonials = (e) => {
    e.preventDefault();
    logFirebaseEvent("TESTIMONIALS_TAB_MAIN_HEADER");
    navigate(`/testimonials`);
  };

  return (
    <div className="dropdown" onClick={() => setOpenDropdown(!openDropdown)}>
      <div
        href="#"
        role="button"
        id="dropdownMenuLink"
        onClick={toggleDropdown}
        aria-expanded={openDropdown}
        className={`d-flex justify-content-between align-content-center ${
          openDropdown ? "text-primary" : ""
        }`}
      >
        About us
        <span className="dropdown-arrow">
          {openDropdown ? (
            <span className="material-symbols-outlined rotate-up">
              keyboard_arrow_up
            </span>
          ) : (
            <span className="material-symbols-outlined rotate-down">
              keyboard_arrow_down
            </span>
          )}
        </span>
      </div>
      {openDropdown && (
        <ul
          className="dropdown-menu horizontal-dropdown"
          aria-labelledby="dropdownMenuLink"
          style={{
            position: "fixed",
            left: 0,
            justifyContent: "center",
          }}
        >
          {(!currentUser ||
            HEADER_TAB_ACCESS.includes(email) ||
            isShowHeader?.isShowResumeTab) && (
            <li>
              <a
                className="dropdown-item"
                role="button"
                onClick={(e) => handleClickTestimonials(e)}
                style={{
                  color: getTestimonialsTabColor(page, wlThemeColor),
                }}
              >
                Testimonials
              </a>
            </li>
          )}

          {(!currentUser ||
            HEADER_TAB_ACCESS.includes(email) ||
            isShowHeader?.isShowCoverLetterTab) && (
            <li>
              <a
                className="dropdown-item"
                role="button"
                onClick={() => {
                  logFirebaseEvent("BLOG_TAB_MAIN_HEADER");
                  window.location.href = "https://www.careerclaw.com/blogs/";
                }}
                style={{
                  color: getBlogTabColor(page, wlThemeColor),
                }}
              >
                Blog
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

AiToolsHeaderDropdown.defaultProps = {
  page: "",
  pageList: "",
  isShowHeader: null,
  wlThemeColor: "",
};

export default AiToolsHeaderDropdown;
